import { ResponsiveEmbed } from "../../../../src/components/blog/ResponsiveEmbed";
import YouTube from "../../../../src/components/blog/YouTube";
import { Dialogue, FigureLabel, GlyphLeft, GlyphRight, Pony } from "../../../../src/components/blog/Text";
import TextBox from "../../../../src/components/blog/Boxes";
import BlogImage from "../../../../src/components/blog/BlogImage";
import * as React from 'react';
export default {
  ResponsiveEmbed,
  YouTube,
  Dialogue,
  FigureLabel,
  GlyphLeft,
  GlyphRight,
  Pony,
  TextBox,
  BlogImage,
  React
};